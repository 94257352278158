import React from "react";
import "./App.css";

const ToggleButton = ({ options, selected, setSelected }) => {
  const optionName = (name) => {
    name = name.toLowerCase();
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  };

  return (
    <div className="toggle-set">
      {options.map((option) => {
        return (
          <button
            className={"toggle " + (option == selected ? "on" : "off")}
            onClick={() => setSelected(option)}
          >
            {optionName(option)}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleButton;
