import React, { useState } from "react";
import ComplianceDisplay from "./ThemeCompareDisplay";
import CopyIcon from "./content_copy_FILL0_wght400_GRAD0_opsz24.svg";
import { ColorInputArray } from "./colorInputs";

const ThemeCompare = () => {
  const [themeColors, setThemeColors] = useState([
    { label: "Color 1", value: "#ffffff" },
    { label: "Color 2", value: "#000000" },
  ]);

  const [showAll, setShowAll] = useState(false);

  const handleCopy = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  function makeCssLabel(color) {
    const spaces = /[\s]+/g;
    var cssLabel = color.label;
    console.log(cssLabel);
    cssLabel = cssLabel.toLowerCase();
    cssLabel = cssLabel.replace(spaces, "-");
    cssLabel = "--" + cssLabel + ": " + color.value + ";";
    return cssLabel;
  }

  return (
    <>
      <div className="main-component">
        <h1>Theme Compliance</h1>
        <button
          onClick={() =>
            handleCopy(
              themeColors
                .map((color) => {
                  return makeCssLabel(color);
                })
                .join("\n")
            )
          }
        >
          <img src={CopyIcon}></img> Copy Theme CSS
        </button>
        <ColorInputArray
          canChangeLength={true}
          colors={themeColors}
          setColors={setThemeColors}
          canChangeLabel={true}
        />
      </div>
      <div className="main-component">
        <ComplianceDisplay themeColours={themeColors} showAll={showAll} />
      </div>
    </>
  );
};
export default ThemeCompare;
