
export function decToHex(decVal) {
  var hexVal = decVal.toString(16);
  if (hexVal.length < 2) {
    hexVal = "0" + hexVal;
  }
  return hexVal;
}

export function parseRGB(color) {
  const colorPat = /#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/g;
  const colorMatch = colorPat.exec(color);
  const valArray = [
    parseInt(colorMatch[1], 16),
    parseInt(colorMatch[2], 16),
    parseInt(colorMatch[3], 16),
  ];
  return valArray;
}

//colorChannelA and colorChannelB are ints ranging from 0 to 255

//rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
//example (red): rgbA = [255,0,0]
export function linearColorMixer(rgbA, rgbB, amountToMix) {
  var r = parseInt(rgbA[0] * amountToMix + rgbB[0] * (1.0 - amountToMix));
  var g = parseInt(rgbA[1] * amountToMix + rgbB[1] * (1.0 - amountToMix));
  var b = parseInt(rgbA[2] * amountToMix + rgbB[2] * (1.0 - amountToMix));
  return "#" + decToHex(r) + decToHex(g) + decToHex(b);
}

function ptMult(point, factor) {
  return [point[0] * factor, point[1] * factor];
}

function ptAdd(point1, point2) {
  return [parseFloat(point1[0] + point2[0]), parseFloat(point1[1] + point2[1])];
}


export function bezierColorMixer(rgbA, rgbB, position, isConcave = true) {

  const p0 = 0.0;
  const p1 = isConcave ? 0.0 : 1.0;
  const p2 = 1.0;

  const amountToMix = (1 - position) * (p0 * (1 - position) + p1 * position) + position * ((1 - position) * p1 + position * p2);

  var r = parseInt(rgbA[0] * amountToMix + rgbB[0] * (1.0 - amountToMix));
  var g = parseInt(rgbA[1] * amountToMix + rgbB[1] * (1.0 - amountToMix));
  var b = parseInt(rgbA[2] * amountToMix + rgbB[2] * (1.0 - amountToMix));
  return "#" + decToHex(r) + decToHex(g) + decToHex(b);
}

export function sineColorMixer(rgbA, rgbB, position) {
  const amountToMix = Math.sin(position * Math.PI - (Math.PI / 2.0)) * 0.5 + 0.5;

  var r = parseInt(rgbA[0] * amountToMix + rgbB[0] * (1.0 - amountToMix));
  var g = parseInt(rgbA[1] * amountToMix + rgbB[1] * (1.0 - amountToMix));
  var b = parseInt(rgbA[2] * amountToMix + rgbB[2] * (1.0 - amountToMix));
  return "#" + decToHex(r) + decToHex(g) + decToHex(b);
}


export function getLuminance(rgb) {
  if (!Array.isArray(rgb)) {
    rgb = parseRGB(rgb);
  }

  var lum = rgb.map(function (val) {
    let v = val / 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return lum[0] * 0.2126 + lum[1] * 0.7152 + lum[2] * 0.0722;
}

export function getContrastRatio(color1, color2) {
  var rgbA = color1;
  if (!Array.isArray(rgbA)) {
    rgbA = parseRGB(color1);
  }
  var rgbB = color2;
  if (!Array.isArray(rgbB)) {
    rgbB = parseRGB(color2);
  }

  var lum1 = getLuminance(rgbA);
  var lum2 = getLuminance(rgbB);

  if (lum1 > lum2) {
    return (0.05 + lum1) / (0.05 + lum2);
  } else {
    return (0.05 + lum2) / (0.05 + lum1);
  }
}

/**
 * Creates a range with the specified number of shades
 */
export const rangeArray = (color1, color2, numShades, colorNameBase = "color-range") => {
  if (numShades < 2) {
    numShades = 2;
  } else if (numShades > 51) {
    numShades = 51;
  }

  var nameBase = colorNameBase.replace(/(?<=[^\s])([\s]+)(?=[^\s])/g, nameBase);
  console.log(nameBase);

  const rgb1 = parseRGB(color1);
  const rgb2 = parseRGB(color2);


  var rangeArray = Array.apply(null, Array(numShades)).map((x, index) => {
    let ratio = ((numShades - 1 - index) * 100.0) / (numShades - 1);
    let ratioLabel = (ratio * 100).toString();
    if (ratioLabel.length < 2) {
      ratioLabel = "0" + ratioLabel;
    }

    let mixedColor = linearColorMixer(parseRGB(rgb1, rgb2, ratio));
    return { label: nameBase + ratioLabel, value: mixedColor };
  })

  return rangeArray;
}

export const ratioArray = (numShades) => {
  var ratioArray = Array.apply(null, Array(numShades)).map((x, index) => ((numShades - 1 - index) * 100.0) / (numShades - 1)
  )

  return ratioArray;
}