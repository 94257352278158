import React, { useEffect, useState } from "react";
import { getContrastRatio } from "./colorFunctions";

const ComplianceDisplay = ({ themeColours }) => {
  const [compliantCombos, setCompliantCombos] = useState(() => {
    var tempCombos = [];
    themeColours.forEach((bgColor) => {
      themeColours.forEach((fgColor) => {
        let contrast = getContrastRatio(bgColor.value, fgColor.value);
        if (contrast > 7 && fgColor.value != bgColor.value) {
          tempCombos.push([fgColor, bgColor]);
        }
      });
    });
    return tempCombos;
  });
  const [allCombos, setAllCombos] = useState(() => {
    var tempCombos = [];
    themeColours.forEach((bgColor) => {
      themeColours.forEach((fgColor) => {
        if (fgColor.value != bgColor.value) {
          tempCombos.push([fgColor, bgColor]);
        }
      });
    });
    return tempCombos;
  });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (themeColours.length > 1) {
      var tempCombos = [];
      themeColours.forEach((bgColor) => {
        themeColours.forEach((fgColor) => {
          if (fgColor.value != bgColor.value) {
            tempCombos.push([fgColor, bgColor]);
          }
        });
      });
      setAllCombos(() => tempCombos);
    }
    if (allCombos.length > 0) {
      var tempCompliant = [];
      allCombos.forEach((combo) => {
        let contrast = getContrastRatio(combo[0].value, combo[1].value);
        if (contrast > 7) {
          tempCompliant.push(combo);
        }
        setCompliantCombos(() => tempCompliant);
      });
    }
  }, [themeColours]);

  return (
    <>
      <h1> Colour Combinations</h1>
      <div class="toggle-set">
        <button
          onClick={() => setShowAll(() => true)}
          className={showAll ? "toggle on" : "toggle off"}
        >
          All
        </button>
        <button
          onClick={() => setShowAll(() => false)}
          className={!showAll ? "toggle on" : "toggle off"}
        >
          Compliant
        </button>
      </div>
      <div className="color-list">
        {showAll ? (
          <>
            {allCombos.length > 0 ? (
              <>
                {allCombos.map((combo) => {
                  return (
                    <div
                      className="color-list-item"
                      style={{
                        background: combo[1].value,
                        color: combo[0].value,
                      }}
                    >
                      <p>Background: {combo[1].label}</p>
                      <p>Foreground: {combo[0].label}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="color-list-item">
                  <p>All theme colours are identical</p>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {compliantCombos.length > 0 ? (
              <>
                {compliantCombos.map((combo) => {
                  return (
                    <div
                      className="color-list-item"
                      style={{
                        background: combo[1].value,
                        color: combo[0].value,
                      }}
                    >
                      <p>Background: {combo[1].label}</p>
                      <p>Foreground: {combo[0].label}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="color-list-item">
                <p>No compliant colour combinations are available</p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ComplianceDisplay;
