import logo from './logo.svg';
import './App.css';
import ColorRange from './ColorRange';
import { useState, useEffect } from 'react';
import ThemeCompare from './ThemeCompare';

function App() {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const userAgentStr = navigator.userAgent.toLowerCase();
    const matchMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgentStr);
    if (matchMobile) {
      setDeviceType(() => "mobile");
    }
  }, [])

  return (
    <div className={'app ' + deviceType}>
      <ColorRange />
      <ThemeCompare />
    </div>
  );
}

export default App;
